<template>
  <div class="whole">
    <Nav></Nav>
    <div class="post-detail desktop-present">
      <div class="postWhole">
        <!-- <div class="post-position">
          您的位置： <span>首页</span> >> 稿件浏览
        </div> -->
        <div class="video-detail" v-if="postInfo.postType == 'VIDEO'">
          <div class="video-play">
            <div class="title">{{ postInfo.title }}</div>
            <video
              :src="postInfo.videoURL"
              :poster="postInfo.posterURL"
              controls
              loop
              width="1200"
              height="523"
            ></video>
          </div>
          <div class="video-text">
            <div v-if="postInfo.extra">
              {{ postInfo.extra.extraInfo.extraString1 }} {{ postInfo.author }}
            </div>
            <div>视频ID：{{ postInfo.id }}</div>
            <div>上传时间：{{ postInfo.upTime }}</div>
            <div>地点：{{ newAddress }}</div>
            <div>类型：{{ newTypes }}</div>
          </div>
          <div class="video-btn" v-if="postInfo.canDownloadMiddlePic">
            <div class="v-b" @click="onVideoDownload(postInfo.id)">
              下载视频
            </div>
          </div>
          <div class="video-maintext">
            <div>正文:</div>
            <pre>{{ postInfo.mainText }}</pre>
          </div>
        </div>
        <div class="post-content" v-else>
          <div class="post-item">
            <div class="post-bigPic">
              <div class="post-info">
                <div class="post-title">{{ postInfo.title }}</div>
              </div>
              <div class="post-pic">
                <div class="post-left" v-if="postInfo.pics">
                  <div
                    class="post-img"
                    v-for="(k, showIndex) in postInfo.pics"
                    :key="k.id"
                    :class="{ active: swiperIndex == showIndex }"
                  >
                    <div
                      class="only-img"
                      :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                      @click="onLookPic(showIndex)"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="post-other">
                <div v-if="postInfo.extra">
                  {{ postInfo.extra.extraInfo.extraString1 }}
                  <span> {{ postInfo.author }} </span>上传时间:
                  {{ postInfo.upTime }}
                </div>
                <div>图片分类：{{ newTypes }}</div>
                <div>事件地点：{{ newAddress }}</div>
              </div>
              <div class="maintext">
                <span>摘要：</span>
                {{ postInfo.mainText }}
              </div>
              <div class="pic-info" v-if="postInfo.pics">
                <div class="pic-length">
                  <i>{{ swiperIndex + 1 }}</i> / {{ postInfo.pics.length }}
                </div>
                <div class="pic-center">
                  {{ postInfo.pics[picIndex].text }} | 图片ID：{{
                    postInfo.pics[picIndex].id
                  }}
                  | 原始图片：{{ postInfo.pics[picIndex].size }} |
                  {{ postInfo.pics[picIndex].height }} x{{
                    postInfo.pics[picIndex].width
                  }}
                </div>
              </div>
              <div class="pic-list">
                <div class="pic-swiper">
                  <div
                    class="pic-show"
                    :style="{ left: transformDistance + 'rem' }"
                    v-if="postInfo.pics"
                  >
                    <div
                      class="pic-item"
                      v-for="(p, litterIndex) in postInfo.pics"
                      :key="p.id + '_pic'"
                      ref="swiperpic"
                      :class="{
                        active: swiperIndex == litterIndex,
                      }"
                      @click="onLookPic(litterIndex)"
                    >
                      <el-checkbox
                        class="selected"
                        v-model="p.selectedPic"
                        @change="onChangeSelect"
                        v-if="loginStatus"
                      ></el-checkbox>
                      <img
                        class="bg-img"
                        :src="p.sImgURL"
                        @click="onEnlarge(litterIndex)"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="pic-text">{{ postInfo.pics[swiperIndex].text }}</div> -->
                <div class="pic-left">
                  <div class="left-icon" @click="onLargeLeft">
                    <div class="left-d el-icon-d-arrow-left"></div>
                  </div>
                </div>
                <div class="pic-right">
                  <div class="right-icon" @click="onLargeRight">
                    <div class="right-icon" @click.stop="onLargeRight">
                      <div class="right-d el-icon-d-arrow-right"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="post-text">
                <div class="text-title">{{ postInfo.title }}</div>
                <div>{{ postInfo.mainText }}</div>
              </div> -->
            </div>
            <div class="post-tip">
              <div class="post-btn">
                <div class="btn">
                  <div class="symbol">
                    <div
                      class="zan"
                      :title="'点赞数：' + postInfo.tagUp"
                      @click="onTagUp"
                    >
                      <i
                        :class="{ add: tagUpAdd }"
                        class="zan-icon el-icon-thumb"
                      ></i
                      ><span class="zan-text"
                        >点赞
                        <i class="number">（{{ postInfo.tagUp }}）</i></span
                      >
                      <!-- <span v-bind:class="{ red: tagStyle }">点赞</span> -->
                    </div>
                    <div class="collect">
                      <div class="shoucang-icon" @click="onCollect()"></div>
                      <span>收藏</span>
                    </div>
                    <div>
                      <div
                        class="share-icon"
                        @click="onGetShare(postInfo.id)"
                      ></div>
                      <span>分享</span>
                    </div>
                    <a class="bshareDiv" href="https://www.bshare.cn/share"></a>
                  </div>
                  <div class="postbtn">
                    <!-- <el-button type="danger" :disabled="!postInfo.downloadOri"> -->
                    <el-button type="danger" @click="onDownNumber('downo')">
                      <div class="down-icon"></div>
                      <div class="number">
                        <span>批量下载原图</span>
                        <span>({{ selectedLength }}张)</span>
                      </div>
                    </el-button>
                    <el-button type="danger" @click="onDownNumber('downm')">
                      <div class="down-icon"></div>
                      <div class="number">
                        <span>批量下载中图</span>
                        <span>({{ selectedLength }}张)</span>
                      </div>
                      <!-- <div
                        class="number"
                        @click="
                          postInfo.downloadMiddle
                            ? onDownNumber('downm')
                            : function () {}
                        "
                      >
                        <span>批量下载中图</span>
                        <span>({{ selectedLength }}张)</span>
                      </div> -->
                    </el-button>
                    <el-button type="danger" @click="onDownNumber('downs')">
                      <div class="down-icon"></div>
                      <div class="number">
                        <span>批量下载小图</span>
                        <span>({{ selectedLength }}张)</span>
                      </div>
                    </el-button>
                    <el-button type="danger" @click="onDownNumber('car')">
                      <div class="car"></div>
                      <div class="number">
                        <span>加入购物车</span>
                        <span>({{ selectedLength }}张)</span>
                      </div>
                    </el-button>
                    <!-- <el-button type="danger" :disabled="!postInfo.canDownloadSignPic">
              <div class="bianji"></div>
              <div
                class="number"
                @click="
                  postInfo.canDownloadSignPic
                    ? onDownNumber('sign')
                    : function () {}
                "
              >
                <span>签发采编平台</span>
                <span>({{ selectedLength }}张)</span>
              </div>
            </el-button> -->
                  </div>
                </div>
              </div>
              <div class="tip">
                <div class="bold">版权声明：</div>
                <div>
                  ①
                  本网所有图片、文字和视频，版权均属“美美吴江”所有，任何媒体、企业、网站或个人未经本网协议授权不得转载、链接或以其他方式复制发表。经本网协议授权的媒体、企业、网站等，在转载或下载使用时必须注明来源“美美吴江”，违者本网将依法追究责任。
                </div>
                <div>
                  ②
                  如本网图文涉及版权等问题，请作者在两周内速来电或来函与本网联系。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>加入收藏失败，请使用Ctrl+D进行添加</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <form :action="cartUrl" ref="addCartPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form :action="ssPicUrl" ref="ssPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form :action="ooPicUrl" ref="ooPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form :action="mmPicUrl" ref="mmPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form :action="signPicUrl" ref="signPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <form
      :action="videoPicUrl"
      ref="videoPicForm"
      method="GET"
      target="_blank"
    ></form>
    <div class="postWhole mobile-present" v-if="!videoStatus">
      <div class="mobile-title">{{ postInfo.title }}</div>
      <div class="mobile-time">
        <div>{{ postInfo.upTime }} {{ postInfo.author }}摄</div>
        <div class="visited" v-if="postInfo.visit != 0">
          访问量：{{ postInfo.visit }}
        </div>
      </div>
      <img
        class="mobile-frontImg"
        :src="postInfo.frontImg"
        @click="onMobileImageClick(postInfo.frontImg)"
      />
      <div class="mobile-frontText" v-if="postInfo.pics">
        <div class="kuo" v-if="!isFrontKuo" @click="onKuo">
          首图小说明： 展开
        </div>
        <div class="kuo" v-if="isFrontKuo" @click="onKuo">
          首图小说明： 收起
        </div>
        <div
          class="text"
          v-bind:class="{ kuo: isFrontKuo }"
          v-if="postInfo.pics"
        >
          <div v-for="h in postInfo.pics" :key="h.id">
            <span v-if="h.front">{{ h.text }}</span>
          </div>
        </div>
      </div>
      <div
        class="mobile-text"
        v-bind:class="{
          active: textMoreShow == true,
          autohieght: textAutoShow == true,
        }"
      >
        {{ postInfo.mainText }}
      </div>
      <div
        class="mobile-moreTextUp"
        v-if="textMoreShow == true && textAutoShow == false"
        @click="onMoreTextUp"
      >
        收起<span class="el-icon-arrow-up"></span>
      </div>
      <div
        class="mobile-moreTextDown"
        v-if="textMoreShow == false && textAutoShow == false"
        @click="onMoreTextDown"
      >
        阅读全文<span class="el-icon-arrow-down"></span>
      </div>

      <div v-for="p in postInfo.pics" :key="p.id + '_mobile_p'">
        <div class="front" v-if="p.front"></div>
        <div class="no-front" v-else>
          <img
            class="mobile-img"
            :src="p.imgURL"
            @click="onMobileImageClick(p.imgURL)"
          />
          <div class="mobile-pictext">{{ p.text }}【ID:{{ p.id }}】</div>
          <div class="mobile-picauthor">
            美美吴江 <span>{{ postInfo.author }}</span> 摄
          </div>
        </div>
      </div>
    </div>
    <div class="postWhole mobile-present" v-if="videoStatus">
      <div class="video-detail">
        <div class="video-play">
          <div class="title">{{ postInfo.title }}</div>
          <video
            :src="postInfo.videoURL"
            :poster="postInfo.videoimgURL"
            controls
            loop
            width="1200"
            height="523"
          ></video>
        </div>
        <div class="video-text">
          <div>作者：{{ postInfo.author }}</div>
          <div>视频ID：{{ postInfo.id }}</div>
          <div>上传时间：{{ postInfo.upTime }}</div>
          <div>地点：{{ newAddress }}</div>
          <div>类型：{{ newTypes }}</div>
          <div class="visited" v-if="postInfo.visit != 0">
            访问量：{{ postInfo.visit }}
          </div>
        </div>
        <div class="video-btn" v-if="postInfo.canDownloadMiddlePic">
          <div class="v-b">下载视频</div>
        </div>
        <div class="video-maintext">
          <div>正文:</div>
          <!-- <pre>{{ postInfo.mainText }}</pre> -->
          <div class="main-text-preview">{{ postInfo.mainText }}</div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = [];
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";
// import gohomePic from '../assets/replay_img.png'
// import QRCode from 'qrcode'
// import bShare from "";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      post: [],
      newTypes: "", // 转换后的类型
      newAddress: "", // 转换后的地址
      isswiperform: false, // 点击移动一个
      isswipermRight: false,
      picIndex: 0,
      swiperIndex: 0, // 轮播的选中的哪一个
      selectedLength: 0,
      ssPicUrl: "", // 小图from地址
      ooPicUrl: "", // 原图from地址
      cartUrl: "", // 购物车from地址
      mmPicUrl: "", // 中图from地址
      signPicUrl: "", // 采编from地址
      videoPicUrl: "", // 视频form地址
      allCheck: false, //默认全选的状态
      selected: [], // 被选中的图片数组
      picIdsString: "",
      selectedPic: "",
      isswiperright: false, // 往右移动
      dialogVisible: false, //显示收藏的弹框
      isIndeterminate: false, // 半选的状态
      transformDistance: 1, // 查看大图时的小图的移动距离
      transformlargeDistance: 1, // 点击小图，中图的变化距离
      videoStatus: false, // 视频稿件的显示与隐藏
      loginStatus: false, // 是否登录的状态
      selectedAllPic: "",
      postInfo: {},
      tagUpAdd: false,
      textAutoShow: false,
      isFrontKuo: false,
      textMoreShow: false, // 是否展开更多文字。mobile下
    };
  },
  created() {
    RD.pure()
      .user()
      .sync()
      .then(() => {
        this.loginStatus = true;
      });
    console.log("status", this.loginStatus);
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      console.log("mu", this.$route.query.id);
      var postId = this.$route.query.id;
      this.post.forEach((k) => {
        if (k.id == postId) {
          this.postInfo = k;
        }
      });
      var shareUrl = window.location.href;
      RD.post()
        .id(this.$route.query.id)
        .one()
        .then((data) => {
          var newArr = [];
          data.eventTypeNamePaths.forEach((i) => {
            var type = [];
            i.forEach((k) => {
              type.push(k);
            });

            newArr.push(type.join(">"));
          });

          thiz.newTypes = newArr.join("●");
          data.pics.forEach((k) => {
            k.imgURL = RD.pic().id(k.id).mImage_URL();
            k.sImgURL = RD.pic().id(k.id).sImage_URL();
            k.selectedPic = false;
          });
          data.frontImg = RD.pic().id(data.frontPicId).mImage_URL();
          var newaddr = [];
          data.eventAddrNamePath.forEach((k) => {
            newaddr.push(k);
          });
          thiz.newAddress = newaddr.join(">");
          if (data.publicForUser) {
            data.userHead = RD.userShow().id(data.userShow.id).headPic_URL();
          }
          if (data.postType == "VIDEO") {
            thiz.videoStatus = true;
            data.posterURL = RD.video().id(data.id).poster_URL();
            data.videoURL = RD.video().id(data.id).mvideo_URL();
          } else {
            thiz.videoStatus = false;
          }
          if (data.mainText.length <= 1200) {
            thiz.textAutoShow = true;
          }
          thiz.postInfo = data;
        });
    },
    // mobile下的展开更多正文
    onMoreTextUp() {
      this.textMoreShow = false;
    },
    // mobile下的展开更多正文
    onMoreTextDown() {
      this.textMoreShow = true;
    },
    onKuo() {
      this.isFrontKuo = !this.isFrontKuo;
    },
    onChangeSelect() {
      var thiz = this;
      thiz.selected = [];
      thiz.postInfo.pics.forEach((k) => {
        if (k.selectedPic == true) {
          thiz.selected.push(k.id);
        }
      });
      thiz.selectedLength = thiz.selected.length;
      if (thiz.selected.length == thiz.postInfo.pics.length) {
        thiz.allCheck = true;
        thiz.isIndeterminate = false;
      } else if (thiz.selected.length == 0) {
        thiz.allCheck = false;
        thiz.isIndeterminate = false;
      } else if (thiz.selected.length >= 1) {
        thiz.isIndeterminate = true;
      }
    },
    onLargeLeft() {
      if (this.swiperIndex == 0) {
        this.swiperIndex = this.swiperIndex + 0;
      } else if (this.swiperIndex == 2) {
        this.swiperIndex = this.swiperIndex - 1;
      } else if (this.swiperIndex == 1) {
        this.swiperIndex = this.swiperIndex - 1;
      } else {
        this.swiperIndex = this.swiperIndex - 2;
      }
      this.$nextTick(function () {
        this.calculate();
      });
    },
    onLargeRight() {
      console.log("jkk", this.swiperIndex);
      if (this.swiperIndex == this.postInfo.pics.length - 2) {
        this.swiperIndex = this.swiperIndex + 1;
      } else if (this.swiperIndex == this.postInfo.pics.length - 3) {
        this.swiperIndex = this.swiperIndex + 1;
      } else if (this.swiperIndex == this.postInfo.pics.length - 1) {
        this.swiperIndex = this.swiperIndex + 0;
      } else {
        this.swiperIndex = this.swiperIndex + 2;
      }
      this.$nextTick(function () {
        this.calculate();
      });
    },
    // 点击大图，查看图片详情
    onLookPic(navIndex) {
      console.log("8989");
      this.swiperIndex = navIndex;
      this.$nextTick(function () {
        this.calculate();
      });
      // this.next()
    },
    // 计算移动距离
    calculate() {
      var toMiddleOffset = 0;
      console.log("mx19", this.swiperIndex);
      for (var i = 0; i < this.swiperIndex; i++) {
        toMiddleOffset += this.$refs.swiperpic[i].clientWidth + 10;
      }
      toMiddleOffset += this.$refs.swiperpic[this.swiperIndex].clientWidth / 2;
      console.log("toMiddleOffset", toMiddleOffset);
      var offset = 479 - toMiddleOffset;
      if (offset > 0) {
        offset = 0;
      }
      var allWidth = 0;
      for (var k = 0; k < this.postInfo.pics.length; k++) {
        allWidth += this.$refs.swiperpic[k].clientWidth + 10;
      }
      if (offset <= 958 - allWidth) {
        offset = 958 - allWidth;
      }
      this.transformDistance = offset / 10;
      console.log("toMiddleOffset", this.transformDistance);
    },
    // 点击小图列表
    onEnlarge(index) {
      var thiz = this;
      thiz.transformlargeDistance = index * 90.9;
      thiz.picIndex = index;
    },
    // 收藏
    onCollect() {
      try {
        window.external.addFavorite(window.location, document.title);
      } catch (e) {
        try {
          window.sidebar.addPanel(window.location, document.title, "");
        } catch (e) {
          // sweetAlert("加入收藏失败，请使用Ctrl+D进行添加");
          this.dialogVisible = true;
        }
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 分享
    onGetShare() {
      console.log("466");
      this.$message("请点击右侧分享按钮进行分享");
    },
    onTagUp() {
      let thiz = this;
      if (this.tagUpAdded) {
        // this.$rtm.message.info('已经赞过了！')
        this.$notify({
          title: "成功",
          message: "已经赞过了！",
          type: "success",
        });
        return;
      }
      RD.pure()
        .post()
        .id(this.postInfo.id)
        .tagUp()
        .then(() => {
          thiz.tagUpAdd = true;
          thiz.tagUpAdded = true;
          // setTimeout(() => {
          thiz.postInfo.tagUp += 1;
          // }, 1500)
        })
        .catch((error) => {
          this.$notify({
            title: "警告",
            message: error.msg,
            type: "warning",
          });
        });
    },
    // 收藏
    onCollect() {
      try {
        window.external.addFavorite(window.location, document.title);
      } catch (e) {
        try {
          window.sidebar.addPanel(window.location, document.title, "");
        } catch (e) {
          // sweetAlert("加入收藏失败，请使用Ctrl+D进行添加");
          this.dialogVisible = true;
        }
      }
    },
    onDownNumber(type) {
      var thiz = this;
      if (thiz.selected.length == 0) {
        thiz.$message("请先勾选图片，然后进行操作");
        return;
      }

      if (type == "downs") {
        if (!thiz.postInfo.downloadSmall) {
          console.log("true", !thiz.postInfo.downloadSmall);

          this.$alert(
            "您没有下载权，可注册或者登录有下载权的账户或在购物车页面进行购买下载",
            "提示",
            {
              confirmButtonText: "确定",
              callback: (action) => {},
            }
          );
          return;
        }
      } else if (type == "downo") {
        if (!thiz.postInfo.downloadOri) {
          this.$alert(
            "您没有下载权，可注册或者登录有下载权的账户或在购物车页面进行购买下载",
            "提示",
            {
              confirmButtonText: "确定",
              callback: (action) => {},
            }
          );
          return;
        }
      } else if (type == "downm") {
        if (!thiz.postInfo.downloadMiddle) {
          this.$alert(
            "您没有下载权，可注册或者登录有下载权的账户或在购物车页面进行购买下载",
            "提示",
            {
              confirmButtonText: "确定",
              callback: (action) => {},
            }
          );
          return;
        }
      } else if (type == "car") {
        if (!thiz.postInfo.addCart) {
          this.$alert(
            "您没有加入购物车的权限，可注册或者登录有加入购物车的权限的账户",
            "提示",
            {
              confirmButtonText: "确定",
              callback: (action) => {},
            }
          );
          return;
        }
      }

      thiz.picIdsString = JSON.stringify(thiz.selected);
      if (type == "car") {
        RD.pure()
          .purchaseOrder()
          .toCart(thiz.selected)
          .then(() => {
            console.log("car success");
            this.$confirm("加入购物车成功", "提示", {
              confirmButtonText: "进入购物车",
              cancelButtonText: "稍后再说",
              type: "success",
            })
              .then(() => {
                let routerUrl = this.$router.resolve({
                  path: "/shopCar.html",
                });
                window.open(routerUrl.href, "_blank");
              })
              .catch(() => {});
          })
          .catch((errMsg) => {
            console.log("errmsg", errMsg);
            thiz.$message(errMsg.msg);
          });
      } else if (type == "downs") {
        thiz.ssPicUrl = RD.pic().ssImageBatch_URL(thiz.selected);
        thiz.$nextTick(() => {
          thiz.$refs.ssPicForm.submit();
        });
      } else if (type == "downo") {
        thiz.ooPicUrl = RD.pic().oImageBatch_URL(thiz.selected);
        thiz.$nextTick(() => {
          thiz.$refs.ooPicForm.submit();
        });
      } else if (type == "downm") {
        thiz.mmPicUrl = RD.pic().mmImageBatch_URL(thiz.selected);
        thiz.$nextTick(() => {
          thiz.$refs.mmPicForm.submit();
        });
      } else if (type == "sign") {
        thiz.signPicUrl = RD.pic().signOut(thiz.selected);
        thiz.$nextTick(() => {
          thiz.$refs.signPicForm.submit();
        });
      }
    },
    tip() {},
  },
};
</script>

<style scoped lang="less">
.post-detail {
  width: 100%;
}
@media all and (max-width: 800px) {
  .postWhole {
    width: 750px;
    height: auto;
    .post-position {
      text-align: left;
      margin-left: 0.5rem;
      font-size: 1.6rem;
    }
    .post-content {
      background-color: #fff;
      margin: 1rem;
      margin-bottom: 0rem;
      .post-bigPic {
        margin: 0.5rem;
        .post-info {
          min-height: 15rem;
          .post-title {
            font-size: 2rem;
            text-align: left;
            padding-top: 2rem;
          }
        }
        .post-pic {
          display: flex;
          height: 500px;
          .post-left {
            display: flex;
            height: 100%;
            width: 100%;
            margin: 0 auto;
            transition: all 500ms ease-in-out;
            flex-direction: row;
            .post-img {
              width: 0rem;
              height: 100%;
              margin: 0 auto;
              background: #000;
              transition: all 0.3s ease-in-out;
              position: relative;
              overflow: hidden;
              .only-img {
                width: 100%;
                height: 100%;
                background: no-repeat 50% / contain;
              }
            }
            .post-img.active {
              width: 100%;
              height: 100%;
            }
          }
          .post-right {
            width: 27%;
            text-align: left;
            padding-left: 2rem;
            background-color: #f5f5f5;
            .pic-title {
              font-size: 1.8rem;
            }
            .pic-size {
              font-size: 1.4rem;
              margin-top: 2rem;
              div {
                height: 3rem;
                line-height: 3rem;
              }
            }
            .pic-other {
              width: 85%;
              background-color: #fff;
              padding: 0 1rem;
              margin-top: 2rem;
              div {
                height: 3rem;
                line-height: 3rem;
              }
            }
            .pic-down {
              height: 5rem;
              margin-top: 2rem;
              .picdown {
                width: 92%;
                height: 3.5rem;
                line-height: 3.5rem;
                border-radius: 1rem;
                text-align: center;
                .el-button {
                  height: 100%;
                  width: 100%;
                }
              }
            }
            .pic-share {
              height: 5rem;
              display: flex;
              margin-top: 2rem;
              div {
                border: 1px solid #ccc;
                border-radius: 1rem;
                height: 3rem;
                width: 43%;
                text-align: center;
                line-height: 3rem;
                margin-right: 1rem;
                cursor: pointer;
              }
            }
          }
        }
        .post-other {
          text-align: left;
          margin-top: 1rem;
          font-size: 1.4rem;
          div {
            height: 2.5rem;
          }
        }
        .maintext {
          text-align: left;
          border-width: 1px;
          border-style: dashed;
          border-color: rgb(204, 204, 204);
          border-image: initial;
          span {
            font-weight: bold;
          }
        }
        .pic-list {
          margin-top: 5rem;
          background-color: #e5e5e5;
          padding-bottom: 4rem;
          position: relative;
          .pic-select {
            height: 5rem;
            text-align: left;
            margin-left: 8.5rem;
            line-height: 5rem;
          }
          .pic-text {
            text-align: left;
          }
          .pic-swiper {
            overflow: hidden;
            width: 100rem;
            height: 22rem;
            margin: 0 auto;
            position: relative;
            .pic-show {
              position: absolute;
              width: auto;
              height: 100%;
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              transition: all 800ms ease-in-out;
              margin-top: 3rem;
              .pic-item {
                width: auto;
                height: 16rem;
                margin-right: 1rem;
                flex-shrink: 0;
                .bg-img {
                  width: 100%;
                  height: 100%;
                  background: no-repeat 50% / contain;
                }
              }
              .pic-item.active {
                border: 1px solid red;
                box-sizing: border-box;
              }
            }
          }
          .pic-left {
            position: absolute;
            left: 0px;
            top: 2rem;
            height: 100%;
            .left-icon {
              position: absolute;
              transition: all 800ms ease-in-out;
              top: -2rem;
              left: 0;
              background-color: rgba(0, 0, 0, 0.4);
              height: 100%;
              width: 5rem;
              font-size: 2rem;
              cursor: pointer;
              z-index: 1;
              .el-icon-d-arrow-left {
                color: #fff;
                font-size: 5rem;
                margin-top: 13rem;
              }
            }
          }
          .pic-right {
            position: absolute;
            right: 5rem;
            top: 0rem;
            height: 100%;
            .right-icon {
              position: absolute;
              transition: all 800ms ease-in-out;
              right: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.2);
              height: 100%;
              width: 5rem;
              font-size: 2rem;
              cursor: pointer;
              z-index: 9999;
              .el-icon-d-arrow-right {
                color: #fff;
                font-size: 5rem;
                margin-top: 13rem;
              }
            }
          }
        }
        .post-text {
          min-height: 15rem;
          margin: 2rem 1rem;
          margin-bottom: 0rem;

          div {
            text-indent: 2rem;
            line-height: 3rem;
            font-size: 1.4rem;
            text-align: left;
          }
        }
      }
    }
    .video-detail {
      min-height: calc(100vh - 28.1rem);
      width: 750px;
      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        font-size: 3rem;
        height: 100px;
        line-height: 100px;
      }
      video {
        object-fit: contain;
        width: 100%;
        height: 100%;
        width: 750px;
        height: 421px;
      }
      .video-text {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.3rem;
        min-height: 4rem;
        padding-top: 1.5rem;
        padding-left: 0.5rem;
        line-height: 3rem;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
      }
      .video-btn {
        height: 50px;
        .v-b {
          width: 10rem;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.5rem;
          border-radius: 2rem;
          border: 1px solid #ccc;
          background-color: #409eff;
          color: #fff;
          cursor: pointer;
        }
      }
      .video-maintext {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        padding-left: 0.5rem;
        font-size: 2.2rem;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
        pre {
          text-align: left;
          white-space: pre-wrap;
          word-wrap: break-word;
          font-size: 1.8rem;
          line-height: 4.5rem;
        }
      }
    }
  }
  .postWhole.mobile-present {
    width: 750px;
    text-align: left;
    min-height: calc(100vh - 217px);
    .mobile-title {
      font-size: 45px !important;
      font-weight: bold;
      font-family: "黑体";
      margin-left: 10px;
    }
    .mobile-time {
      font-size: 24px;
      margin: 10px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      .editor {
        margin-left: 5px;
      }
      .visited {
        width: auto;
        line-height: 31px;
        font-size: 24px;
        color: red;
        margin-left: 15px;
      }
    }
    .mobile-text {
      font-size: 36px !important;
      margin: 35px 35px 0px;
      line-height: 65px;
      white-space: pre-wrap;
      height: 730px;
      overflow: hidden;
      text-indent: 67px;
    }
    .active {
      height: auto !important;
    }
    .autohieght {
      height: auto !important;
    }
    .mobile-moreTextDown {
      height: auto;
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px;
    }
    .mobile-editor {
      font-size: 30px;
      position: relative;
      height: 70px;
      line-height: 50px;
      .editor {
        position: absolute;
        right: 150px;
      }
    }
    .mobile-moreTextUp {
      height: 50px;
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px;
    }
    .mobile-frontImg {
      display: block !important;
      width: 750px;
    }
    .mobile-frontText {
      height: auto;
      font-size: 21px;
      margin-top: 15px;
      line-height: 30px;
      margin-left: 10px;
      font-weight: bold;
      font-family: "宋体";
      .text {
        height: 0px;
        display: none;
      }
      .kuo {
        display: block;
        height: auto;
      }
    }
    .front {
      display: none;
    }
    .no-front {
      width: 750px;
      .mobile-img {
        width: 100%;
      }
    }

    .mobile-pictext {
      font-size: 30px;
      text-align: left;
      margin: 10px 0px;
      width: 730px;
      padding: 0rem 10px;
      span {
        margin: 0px 10px;
      }
    }
    .mobile-picauthor {
      text-align: center;
      font-size: 30px;
      margin: 10px 0px;
      width: 730px;
      padding: 0rem 10px;
    }
    .video-detail {
      min-height: calc(100vh - 28.1rem);
      width: 750px;
      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        font-size: 3rem;
        height: 10rem;
        line-height: 10rem;
      }
      video {
        width: 750px;
        height: 422px;
        object-fit: cover;
      }
      .video-text {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        display: flex;
        flex-wrap: wrap;
        font-size: 16px;
        min-height: 4rem;
        padding-top: 1.5rem;
        padding-left: 0.5rem;
        line-height: 40px;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
        .visited {
          color: red;
        }
      }
      .video-btn {
        height: 50px;
        .v-b {
          width: 100px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          border-radius: 20px;
          border: 1px solid #ccc;
          background-color: #409eff;
          color: #fff;
          cursor: pointer;
        }
      }
      .video-maintext {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        padding-left: 0.5rem;
        font-size: 16px;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
        .main-text-preview {
          text-align: left;
          text-indent: 3rem;
          line-height: 2.2rem;
          font-size: 1.6rem;
          height: 11rem;
          text-overflow: ellipsis;
          overflow-y: auto;
          display: -webkit-box;
          white-space: pre-wrap;
          // -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .postWhole {
    min-height: 66.6rem;
    width: 120rem;
    margin: 0rem auto;
    position: relative;
    .post-position {
      text-align: left;
      font-size: 1.5rem;
      min-height: 4rem;
      padding: 3px 0px 0px 3px;
      line-height: 40px;
      span {
        color: red;
        font-weight: bold;
      }
    }
    .video-detail {
      min-height: calc(100vh - 28.1rem);
      width: 120rem;
      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        font-size: 3rem;
        height: 10rem;
        line-height: 10rem;
      }
      video {
        object-fit: contain;
        width: 100%;
        height: 100%;
        width: 120rem;
        height: 72rem;
      }
      .video-text {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.3rem;
        min-height: 4rem;
        padding-top: 1.5rem;
        padding-left: 0.5rem;
        line-height: 3rem;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
      }
      .video-btn {
        height: 5rem;
        .v-b {
          width: 10rem;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.5rem;
          border-radius: 2rem;
          border: 1px solid #ccc;
          background-color: #409eff;
          color: #fff;
          cursor: pointer;
        }
      }
      .video-maintext {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        padding-left: 0.5rem;
        font-size: 2.2rem;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
        pre {
          text-align: left;
          white-space: pre-wrap;
          word-wrap: break-word;
          font-size: 1.8rem;
          line-height: 4.5rem;
        }
      }
    }
    .post-content {
      margin-bottom: 0rem;
      background-color: #fff;
      .post-item {
        .post-bigPic {
          width: 120rem;
          margin: 0.5rem auto;
          position: relative;
          background-color: #fff;
          .post-info {
            min-height: 3.5rem;
            width: 120rem;
            margin: 0 auto;
            .post-title {
              font-size: 3rem;
              text-align: center;
              padding-top: 2rem;
              text-align: left;
            }
          }
          .post-other {
            width: 120rem;
            margin: 0 auto;
            text-align: left;
            margin-top: 1rem;
            font-size: 1.5rem;
            display: flex;
            background-color: #ccc;
            div {
              height: 3rem;
              line-height: 3rem;
              margin-right: 3rem;
            }
            span {
              margin-right: 1.8rem;
              i {
                font-style: normal;
                color: #000;
              }
            }
          }
          .maintext {
            width: 118rem;
            margin: 0 auto;
            text-align: left;
            border-width: 1px;
            border-style: dashed;
            border-color: rgb(204, 204, 204);
            border-image: initial;
            padding: 20px 10px;
            font-size: 16px;
            margin-top: 10px;
            line-height: 25px;
            span {
              font-weight: bold;
            }
          }
          .post-pic {
            display: flex;
            height: 62.6rem;
            margin-top: 20px;
            .post-left {
              display: flex;
              height: 100%;
              width: 100%;
              margin: 0 auto;
              transition: all 500ms ease-in-out;
              flex-direction: row;
              .post-img {
                width: 0rem;
                height: 100%;
                margin: 0 auto;
                // background: #000;
                transition: all 0.3s ease-in-out;
                position: relative;
                overflow: hidden;
                .only-img {
                  width: 100%;
                  height: 100%;
                  background: no-repeat 50% / contain;
                }
              }
              .post-img.active {
                width: 100%;
                height: 100%;
              }
            }
            //   .post-right {
            //     width: 27%;
            //     text-align: left;
            //     padding-left: 2rem;
            //     background-color: #f5f5f5;
            //     .pic-title {
            //       font-size: 1.8rem;
            //     }
            //     .pic-size {
            //       font-size: 1.4rem;
            //       margin-top: 2rem;
            //       div {
            //         height: 3rem;
            //         line-height: 3rem;
            //       }
            //     }
            //     .pic-other {
            //       width: 85%;
            //       background-color: #fff;
            //       padding: 0 1rem;
            //       margin-top: 2rem;
            //       div {
            //         height: 3rem;
            //         line-height: 3rem;
            //       }
            //     }
            //     .pic-down {
            //       height: 5rem;
            //       margin-top: 2rem;
            //       .picdown {
            //         width: 92%;
            //         height: 3.5rem;
            //         line-height: 3.5rem;
            //         border-radius: 1rem;
            //         text-align: center;
            //         .el-button {
            //           width: 100%;
            //           height: 100%;
            //         }
            //       }
            //     }
            //     .pic-share {
            //       height: 5rem;
            //       display: flex;
            //       margin-top: 2rem;
            //       div {
            //         border: 1px solid #ccc;
            //         border-radius: 1rem;
            //         height: 3rem;
            //         width: 43%;
            //         text-align: center;
            //         line-height: 3rem;
            //         margin-right: 1rem;
            //         cursor: pointer;
            //       }
            //     }
            //   }
          }
          .pic-info {
            text-align: left;
            width: 104rem;
            margin: 20px auto;
            display: flex;
            .pic-length {
              font-size: 20px;
              i {
                color: #e55656;
                font-size: 30px;
              }
            }
            .pic-center {
              margin-left: 20px;
              font-size: 14px;
              width: 87rem;
              text-align: center;
            }
          }
          .pic-list {
            position: relative;
            // background-color: rgba(0, 0, 0, 0.55);
            width: 96%;
            margin: 20px auto;
            // position: absolute;
            // top: 55.2rem;
            .pic-select {
              height: 5rem;
              text-align: left;
              margin-left: 8.5rem;
              line-height: 5rem;
            }
            .pic-text {
              text-align: left;
              margin-left: 9rem;
            }
            .pic-swiper {
              overflow: hidden;
              width: 100rem;
              height: 12rem;
              margin: 0 auto;
              position: relative;
              .pic-show {
                position: absolute;
                width: auto;
                height: 100%;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                transition: all 800ms ease-in-out;
                box-sizing: border-box;
                .pic-item {
                  width: auto;
                  height: 98%;
                  margin-right: 1rem;
                  flex-shrink: 0;
                  box-sizing: border-box;
                  position: relative;
                  .bg-img {
                    height: 100%;
                    background: no-repeat 50% / contain;
                  }
                  .selected {
                    position: absolute;
                    top: 0rem;
                    left: 0rem;
                  }
                }
                .pic-item.active {
                  border: 1px solid red;
                  box-sizing: border-box;
                }
              }
            }
            .pic-left {
              position: absolute;
              width: 5rem;
              left: 0px;
              top: 2rem;
              height: 100%;
              .left-icon {
                position: absolute;
                transition: all 800ms ease-in-out;
                top: -2rem;
                left: 0;
                background-color: rgba(0, 0, 0, 0.4);
                height: 100%;
                width: 5rem;
                font-size: 2rem;
                cursor: pointer;
                z-index: 1;
                .el-icon-d-arrow-left {
                  color: #fff;
                  font-size: 5rem;
                  margin-top: 3rem;
                }
              }
            }
            .pic-right {
              position: absolute;
              right: 5rem;
              top: 0rem;
              height: 100%;
              .right-icon {
                position: absolute;
                transition: all 800ms ease-in-out;
                right: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.2);
                height: 100%;
                width: 5rem;
                font-size: 2rem;
                cursor: pointer;
                z-index: 9999;
                .el-icon-d-arrow-right {
                  color: #fff;
                  font-size: 5rem;
                  margin-top: 3rem;
                }
              }
            }
          }
          .post-text {
            min-height: 10rem;
            margin: 2rem 1rem;
            margin-bottom: 0rem;
            background-color: #fff;
            .text-title {
              font-size: 1.8rem;
            }
            div {
              text-indent: 2rem;
              line-height: 3rem;
              font-size: 1.4rem;
              text-align: left;
            }
          }
        }
        .post-tip {
          text-align: left;
          .post-btn {
            // height: 6rem;
            text-align: center;
            .btn {
              width: 120rem;
              margin: 10px auto;
              display: flex;
              .symbol {
                height: auto;
                width: 54rem;
                margin: 2.5rem auto;
                line-height: 4rem;
                display: flex;
                div {
                  border: 0.1rem solid #ccc;
                  width: 17rem;
                  height: 4rem;
                  margin: 1rem auto;
                  display: flex;
                  border-radius: 5px;
                  background-color: #f56c6c;
                  color: #fff;
                  .shoucang-icon,
                  .share-icon {
                    width: 2.2rem;
                    height: 2.2rem;
                    border: none;
                    margin-left: 5.5rem;
                    margin-top: 0.8rem;
                    cursor: pointer;
                    background-color: #fff;
                  }

                  span {
                    display: inline-block;
                    width: 4.4rem;
                    height: 3rem;
                    margin-right: 5rem;
                    font-size: 1.4rem;
                  }
                  .shoucang-icon {
                    background: no-repeat url(../assets/shoucang.png);
                    background-size: cover;
                  }
                  .share-icon {
                    background: no-repeat url(../assets/fenxiang_2.png);
                    background-size: cover;
                  }
                  .red {
                    color: red;
                  }
                }
                .zan {
                  border: 0.1rem solid #ccc;
                  width: 17rem;
                  height: 4rem;
                  margin: 1rem auto;
                  display: flex;
                  line-height: 4rem;
                  .zan-icon {
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    margin-left: 2.5rem;
                    margin-top: 0.8rem;
                  }
                  ::v-deep .el-icon-thumb {
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    font-size: 2rem;
                  }
                  .zan-icon.add {
                    color: red;
                  }
                  span {
                    cursor: pointer;
                    position: relative;
                    margin-left: 1.7rem;
                    i {
                      margin-right: 0.6rem;
                    }
                  }
                  .zan-text {
                    width: 12rem;
                    height: 2rem;
                    margin-left: 1rem;
                    margin-right: 0rem;
                    display: flex;
                    font-size: 1.4rem;
                    .number {
                      font-style: normal;
                      // color: red;
                    }
                  }
                  span.add i {
                    transition: all 1500ms ease-in-out;
                    transform: rotateZ(1440deg) scale(1.5);
                  }
                  span::after {
                    content: "+1";
                    color: red;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    opacity: 0;
                  }
                  span.add::after {
                    transition: all 1500ms ease-in-out;
                    top: 5rem;
                    right: 5rem;
                    transform: scale(3.5);
                    opacity: 1;
                  }
                }
              }
              .postbtn {
                margin-top: 1.6rem;
                .el-button {
                  text-align: left;
                  width: 15rem;
                  height: 4rem;
                  margin-top: 2rem;
                }
                .el-button:first-child {
                  margin-left: 1rem;
                }
              }
            }
          }
          .tip {
            margin-left: 5px;
            font-size: 1.6rem;
            .bold {
              font-weight: bold;
            }
            div {
              font-size: 1.4rem;
              line-height: 3rem;
            }
          }
        }
      }
    }
  }
}
</style>
